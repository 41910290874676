import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import CKDocs from '@workday/canvas-kit-docs/dist/mdx/react/menu/Menu.mdx';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TabPanel = makeShortcode("TabPanel");
const PackageInfo = makeShortcode("PackageInfo");
const NoticeCard = makeShortcode("NoticeCard");
const InternalContent = makeShortcode("InternalContent");
const SideBySide = makeShortcode("SideBySide");
const Suggestion = makeShortcode("Suggestion");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TabPanel data-id="Web" mdxType="TabPanel">
      <PackageInfo github="/modules/react/menu" storybook="https://workday.github.io/canvas-kit/?path=/story/components-popups-menu--basic" figma="https://www.figma.com/design/6Y3uHyGKnS8m0DzWBLeL73/Canvas-Web-v11?node-id=5279-338&t=UhTeJ6F4tDSMVJmL-4" package="@workday/canvas-kit-react" mdxType="PackageInfo" />
      <NoticeCard headingText="Menu (Main) vs. Menu (Preview)" linkText="Menu (Preview) Documentation" linkTo="https://workday.github.io/canvas-kit/?path=/docs/preview-menu--basic" mdxType="NoticeCard">
        <p>{`We recommend you use the Menu in the Main package (`}<inlineCode parentName="p">{`@workday/canvas-kit-react`}</inlineCode>{`) documented here on
this page. The Menu in the Preview package (`}<inlineCode parentName="p">{`@workday/canvas-kit-preview-react`}</inlineCode>{`) has been
`}<a parentName="p" {...{
            "href": "/help/upgrade-guides/canvas-kit-v8-upgrade-guide#menu-preview"
          }}>{`marked for soft-deprecation`}</a>{` in
Canvas Kit v8 and will be removed in v9.`}</p>
      </NoticeCard>
      <h2 {...{
        "id": "anatomy"
      }}>{`Anatomy`}</h2>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/35bd36c22b70a507fe91f654b051c267/536c7/component-anatomy-popup-menu.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "44.5945945945946%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Image of a Popup Menu sample showing different possible states.",
              "title": "Image of a Popup Menu sample showing different possible states.",
              "src": "/static/35bd36c22b70a507fe91f654b051c267/50383/component-anatomy-popup-menu.png",
              "srcSet": ["/static/35bd36c22b70a507fe91f654b051c267/1efb2/component-anatomy-popup-menu.png 370w", "/static/35bd36c22b70a507fe91f654b051c267/50383/component-anatomy-popup-menu.png 740w", "/static/35bd36c22b70a507fe91f654b051c267/536c7/component-anatomy-popup-menu.png 1480w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <ol>
        <li parentName="ol"><strong parentName="li">{`Container:`}</strong>{` Rectangular container that houses the icon and text for menu list items.`}</li>
        <li parentName="ol"><strong parentName="li">{`Text:`}</strong>{` Text for indicating where the link leads to when the menu is clicked on. Text can
overflow to the next line but avoid going over more than two lines of text.`}</li>
        <li parentName="ol"><strong parentName="li">{`Icons:`}</strong>{` Icons are optional and can be positioned before or after the text.`}</li>
      </ol>
      <h2 {...{
        "id": "usage-guidance"
      }}>{`Usage Guidance`}</h2>
      <ul>
        <li parentName="ul">{`Popup Menus can appear next to, in front of, above, or below the element that launched them, such
as Dropdown Buttons, Dropdown Icons,
`}<a parentName="li" {...{
            "href": "/components/buttons/button"
          }}>{`icon only Primary/Secondary/Tertiary Button variants`}</a>{` or by
right-clicking a contextual item.`}</li>
        <li parentName="ul">{`Popup Menus should overlap and visually look like they are in front of other UI elements. They
should always be positioned within the viewable areas of the screen and be 8px away from the
element that launched them.`}</li>
        <li parentName="ul">{`Popup Menus should always contain a list of menu selections, which are options users can choose
from. The list should be scannable, kept as concise as possible, and written in title case instead
of sentences.`}</li>
        <li parentName="ul">{`Consider how important each option is. The list of options should be sorted in a logical order,
such as alphabetical, chronological, order of importance, and so on.`}</li>
      </ul>
      <h3 {...{
        "id": "when-to-use"
      }}>{`When to Use`}</h3>
      <ul>
        <li parentName="ul">{`In most cases, as with Overflow Menus, where there aren't enough space on screen to show all the
actions, there could be between 1-7 items to choose from. However, there shouldn't be more than 15
items listed at once on a single Popup Menu.`}</li>
        <li parentName="ul">{`When users must make a single selection from the list of options.`}</li>
      </ul>
      <h3 {...{
        "id": "when-to-use-something-else"
      }}>{`When to Use Something Else`}</h3>
      <ul>
        <li parentName="ul">{`Consider using a `}<a parentName="li" {...{
            "href": "/components/inputs/switch"
          }}>{`Switch`}</a>{` if the only options are yes or no.`}</li>
        <li parentName="ul">{`For a list between 2 to 7 predefined options, consider using
`}<a parentName="li" {...{
            "href": "/components/inputs/radio"
          }}>{`a Radio input`}</a>{` to select one option or
`}<a parentName="li" {...{
            "href": "/components/inputs/checkbox"
          }}>{`Checkboxes`}</a>{` to select multiple options. Radio and Checkbox groups
display all options upfront and do not require the user to interact with the input to view the
list of options.`}</li>
        <li parentName="ul">{`Use a Prompt when the number of list items is large or unknown. Prompts have search capabilities
and folders which provide users with the means to browse options. Prompts can be configured to
support single or multi-select.`}</li>
      </ul>
      <CKDocs mdxType="CKDocs" />
      <h2 {...{
        "id": "accessibility-guidelines"
      }}>{`Accessibility Guidelines`}</h2>
      <h3 {...{
        "id": "keyboard-interaction"
      }}>{`Keyboard Interaction`}</h3>
      <p>{`Each menu item must have a focus indicator that is highly visible against the background and against
the non-focused state. Refer to `}<a parentName="p" {...{
          "href": "/guidelines/accessibility/accessible-color"
        }}>{`Accessible Colors`}</a>{` for more
information.`}</p>
      <p>{`Menus must support the following keyboard interactions:`}</p>
      <ul>
        <li parentName="ul"><inlineCode parentName="li">{`Enter`}</inlineCode>{` or `}<inlineCode parentName="li">{`Space`}</inlineCode>{`: activates the focused menu item`}</li>
        <li parentName="ul"><inlineCode parentName="li">{`Esc`}</inlineCode>{`: dismisses the opened menu and returns focus to the button that invoked the menu`}</li>
        <li parentName="ul"><inlineCode parentName="li">{`Up Arrow`}</inlineCode>{` or `}<inlineCode parentName="li">{`Down Arrow`}</inlineCode>{`: navigates among the menu items cyclically`}</li>
        <li parentName="ul"><inlineCode parentName="li">{`Home`}</inlineCode>{` or `}<inlineCode parentName="li">{`End`}</inlineCode>{`: navigates to the first menu item, or the last menu item respectively`}</li>
      </ul>
      <h3 {...{
        "id": "screen-reader-interaction"
      }}>{`Screen Reader Interaction`}</h3>
      <p>{`Menus must communicate the following to users:`}</p>
      <ul>
        <li parentName="ul">{`A button will invoke a menu or popup`}</li>
        <li parentName="ul">{`Focus has been moved into an opened menu`}</li>
        <li parentName="ul">{`The correct number of items in the menu`}</li>
        <li parentName="ul">{`The name of the focused menu item`}</li>
      </ul>
      <h3 {...{
        "id": "design-annotations-needed"
      }}>{`Design Annotations Needed`}</h3>
      <ul>
        <li parentName="ul">{`Write an accessible name for icon-only button variants invoking menus.`}</li>
        <li parentName="ul">{`Declare whether any icons used in menu items are decorative, or require additional text
alternatives.`}</li>
      </ul>
      <h3 {...{
        "id": "implementation-markup-needed"
      }}>{`Implementation Markup Needed`}</h3>
      <ul>
        <li parentName="ul">{`For buttons invoking menus, an`}<inlineCode parentName="li">{`aria-haspopup=”true”`}</inlineCode>{` property and an `}<inlineCode parentName="li">{`aria-expanded`}</inlineCode>{` attribute
must be added.`}</li>
        <li parentName="ul">{`[Included in component]`}{` The menu container must have an ARIA \`role=”menu” attribute.`}</li>
        <li parentName="ul">{`[Included in component]`}{` All child elements inside the menu container must have an ARIA
\`role=”menuitem” attribute.`}</li>
        <li parentName="ul">{`[Included in component]`}{` Use
`}<a parentName="li" {...{
            "href": "https://www.w3.org/WAI/ARIA/apg/practices/keyboard-interface/#kbd_roving_tabindex"
          }}>{`“roving tabindex” technique`}</a>{`
for managing focus inside the menu. Set `}<inlineCode parentName="li">{`tabindex=”0”`}</inlineCode>{` on the focused menu item, and set to `}<inlineCode parentName="li">{`”-1”`}</inlineCode>{`
on remaining menu items.`}</li>
      </ul>
      <h2 {...{
        "id": "content-guidelines"
      }}>{`Content Guidelines`}</h2>
      <ul>
        <li parentName="ul">{`When writing Menu list items, refer to the `}<a parentName="li" {...{
            "href": "/guidelines/content/ui-text/dropdown-menus"
          }}>{`Menus`}</a>{` section of the
Content Style Guide.`}</li>
      </ul>
    </TabPanel>
    <InternalContent mdxType="InternalContent">
      <TabPanel data-id="iOS" mdxType="TabPanel">
        <PackageInfo figma="https://www.figma.com/file/wUewAjqFBnLx2ka2B49FUR/%F0%9F%9A%A7-BETA%3A-Mobile-UI-Components-%F0%9F%9A%A7?type=design&node-id=88%3A13961&mode=design&t=aFvEj4yRb75k5JJT-1" mdxType="PackageInfo" />
        <h2 {...{
          "id": "anatomy-1"
        }}>{`Anatomy`}</h2>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/7fbf5cc820b51cb271e2b625dc163071/536c7/menu-anatomy.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "28.10810810810811%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAr0lEQVR42pWQbQvCMAyE9/9/oKJMkbGxDmGj25q+pT2z4gcFcRoIgVLunrsKr5NzOenUg48dfAiI28aIIJeZ5UvGt6k+vroINg5KKczzDCKC1hrOOXBkMf5V8Omeb3dwrTA0XRFaRHSaRpAlcEr/E2bjwavFsq4IQsScECXuJhYOLdK5fwPYJUz1gHhRUNcGuh1EeME0jjASfTMC+T87DELjPMhQWWut9GhLh2kn8gPrftfZ+IJiRAAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "the basic elements of a menu",
                "title": "the basic elements of a menu",
                "src": "/static/7fbf5cc820b51cb271e2b625dc163071/50383/menu-anatomy.png",
                "srcSet": ["/static/7fbf5cc820b51cb271e2b625dc163071/1efb2/menu-anatomy.png 370w", "/static/7fbf5cc820b51cb271e2b625dc163071/50383/menu-anatomy.png 740w", "/static/7fbf5cc820b51cb271e2b625dc163071/536c7/menu-anatomy.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <ol>
          <li parentName="ol"><strong parentName="li">{`Container:`}</strong>{` A dynamic and lightweight container that houses icons and list items.`}</li>
          <li parentName="ol"><strong parentName="li">{`Icon (Optional):`}</strong>{` Descriptive iconography that precedes and supplements the list item.`}</li>
          <li parentName="ol"><strong parentName="li">{`List Item:`}</strong>{` Text for a menu item.`}</li>
          <li parentName="ol"><strong parentName="li">{`Divider (Optional):`}</strong>{` A break in menu items to group related items or differentiate unrelated
items.`}</li>
        </ol>
        <h2 {...{
          "id": "usage-guidance-1"
        }}>{`Usage Guidance`}</h2>
        <h4 {...{
          "id": "item-prominence"
        }}>{`Item Prominence`}</h4>
        <p>{`Within a Menu, the most frequently used items should be in the most prominent location at the top.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/bb31a8607fd116f99f376a6a64c559a9/536c7/menu-item%3Dprominence-iOS.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "63.51351351351351%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAACDUlEQVR42o2Sy27TQBSG83h0EykbHoVVN7DBIDaVoGSRFjWktwUqtEl8SxELFjwAgaaxncZuSXyP49i59+fMtEiGlraWjs54zsw3/7nk+q6PP9ZzPO7dYADHD2F7wQ1j+yyePZ+1XBbGDl70HZy0NehnJn7ZLt9nxtbM2H5L02l9df5faC4La+sdvC0WsVUuQxAEFAoF5PN5PFpZ4Z79C8ILlDY28PrNOoczxVkoB7KXh8kY1boEWRIxn89gWRYqlQrebW6iVCpxv729A03TEAQh9vb2oRx/5vfY/RvAiAJ1ScYnUcZoPMPl5RK3fVEUYr5YQJZlyGqDgJPbgeylI1HCq919OIMYxnWtWlTPZsuA2f2JSf8DxnYVSFQoH19Cbnz5v0IWOCTgc6rfed+m+li8RnrnDIblodv+CjQLWBqrwERC4+AZJPUOYEyBg7qIx2tFtLvnpExH8+SUVBqwei7/b33/higweepCeZ1SPr475UNqytP3O6TQ4SlrpM4L2UyyuQvp0QlMfwpW3Sdbu1DuA9aoIRKlPaK17dIo0DiE0RAB1dR2fAySOdQfA65QpWmQlHuawrp8VK3xwKluoG10COzD8UJ0zB6SdExrnwPr4gOANVKnKAq/MJ3NsFwuEKVT6E6KIXn2LWhkOJDqLakPAMrXwNEo4YpY+mGccp+kKeI44XGWydUc/t3l3/1Coe6uigLnAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "prominence of list items in a menu",
                "title": "prominence of list items in a menu",
                "src": "/static/bb31a8607fd116f99f376a6a64c559a9/50383/menu-item%3Dprominence-iOS.png",
                "srcSet": ["/static/bb31a8607fd116f99f376a6a64c559a9/1efb2/menu-item%3Dprominence-iOS.png 370w", "/static/bb31a8607fd116f99f376a6a64c559a9/50383/menu-item%3Dprominence-iOS.png 740w", "/static/bb31a8607fd116f99f376a6a64c559a9/536c7/menu-item%3Dprominence-iOS.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h4 {...{
          "id": "iconography-usage"
        }}>{`Iconography Usage`}</h4>
        <p>{`Use meaningful supplementary icons to reinforce the Menu item’s title and improve scannability`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/7b50666de39d06b7066bf01c6d23f85e/536c7/menu-iconography-iOS.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "63.51351351351351%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAACBUlEQVR42o2SS2/TQBDH8/HoJVLufA5OnDggg8QBCUIPSVGjlARVBQqJvbYTxJUPkEJVp6BE0Mbx+x0/8md2C1KgD2pptOuZ2d/Of2Zr50sbf+zMtMS6dDyYtouF5Vwy7ufxzfxNq23CeOKPcxNfTwxMv8/wc7EUfm58z437j40p7S/y/4XWNmEn0294sb2N3U4HkiSh0WigXq/jztaWWPm/JD1Cq93Gs+dNAecVb0IFkN8cxCkGMoPKFBRFjvl8jm63i5c7O2i1WmLd23sFwzDgOC76/dfQxh/FOX7+EtCngMxUHDINUZZjva5w1ef7LoqyhKqqUPURAbOrgfym97KCp/sH4KgwCKgSh8yGRbJCb4b07ADZYgDEOrR3j6GOPl1fYUiBw6GCJ/0eVVAgSRJhcRxilQNl8AWYNFCd3gcyhtHbB2D6DcAoyfBmKONusw03jOC5PizLJYk+9bREQuDjyWf4zkxIlzpNkjy+XrKokCQ/7O1jYeaYGiGOJj71ci0AeVEgiDLM7Fy05N5uD9pNQF76UGEY67oAxJTIrSJgxZk0JD8poR95Iq7Ta2Da6P9ARtPjPfS8C7llWYkqq6pEnGYwLVsAZeWWQE3TxIFVTtLoeQTJCqdmijAthL8knwBSe5h+C6BKQK4wjGKSnCAivxskoscxTZ37+fdhMPz9Dv+e8i+eqamNhytZPQAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "using icons in to enhance a menu",
                "title": "using icons in to enhance a menu",
                "src": "/static/7b50666de39d06b7066bf01c6d23f85e/50383/menu-iconography-iOS.png",
                "srcSet": ["/static/7b50666de39d06b7066bf01c6d23f85e/1efb2/menu-iconography-iOS.png 370w", "/static/7b50666de39d06b7066bf01c6d23f85e/50383/menu-iconography-iOS.png 740w", "/static/7b50666de39d06b7066bf01c6d23f85e/536c7/menu-iconography-iOS.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h4 {...{
          "id": "sectioning"
        }}>{`Sectioning`}</h4>
        <p>{`Adding titles to Menu sections increases visual noise on a screen.`}</p>
        <SideBySide mdxType="SideBySide">
          <Suggestion status="do" guidance="Use a divider to differentiate sections as necessary." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "696px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/bd7dfba36877163e836316a7f6b79065/82158/menu-do-divider-iOS.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "100%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Menu with a divider differentiating sections",
                    "title": "Menu with a divider differentiating sections",
                    "src": "/static/bd7dfba36877163e836316a7f6b79065/82158/menu-do-divider-iOS.png",
                    "srcSet": ["/static/bd7dfba36877163e836316a7f6b79065/1efb2/menu-do-divider-iOS.png 370w", "/static/bd7dfba36877163e836316a7f6b79065/82158/menu-do-divider-iOS.png 696w"],
                    "sizes": "(max-width: 696px) 100vw, 696px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
          <Suggestion status="error" guidance="Title Menu sections unless absolutely necessary." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "696px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/1f9288de0771df643616417f75099e7f/82158/menu-dont-subtitle-iOS.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "100%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Menu with subtitles",
                    "title": "Menu with subtitles",
                    "src": "/static/1f9288de0771df643616417f75099e7f/82158/menu-dont-subtitle-iOS.png",
                    "srcSet": ["/static/1f9288de0771df643616417f75099e7f/1efb2/menu-dont-subtitle-iOS.png 370w", "/static/1f9288de0771df643616417f75099e7f/82158/menu-dont-subtitle-iOS.png 696w"],
                    "sizes": "(max-width: 696px) 100vw, 696px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
        </SideBySide>
        <h4 {...{
          "id": "ui-state-changes"
        }}>{`UI State Changes`}</h4>
        <p>{`Confusion between when to use a Menu versus an Action Sheet is common. Usage is very context
dependent and should be consistent in a flow.`}</p>
        <Suggestion status="error" guidance="Link between the Menu and Action Sheet, as it may lead to a contextually disorienting experience for the user. Use either the menu or the action sheet in one flow." mdxType="Suggestion">
          <p><span parentName="p" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "740px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/a2ae524af61ee17068f50e53f118eaab/536c7/menu-menu-to-action-sheet-dont-iOS.png",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": "noopener"
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "47.027027027027025%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "Image of a flow that links Menu to Action Sheet after an interaction.",
                  "title": "Image of a flow that links Menu to Action Sheet after an interaction.",
                  "src": "/static/a2ae524af61ee17068f50e53f118eaab/50383/menu-menu-to-action-sheet-dont-iOS.png",
                  "srcSet": ["/static/a2ae524af61ee17068f50e53f118eaab/1efb2/menu-menu-to-action-sheet-dont-iOS.png 370w", "/static/a2ae524af61ee17068f50e53f118eaab/50383/menu-menu-to-action-sheet-dont-iOS.png 740w", "/static/a2ae524af61ee17068f50e53f118eaab/536c7/menu-menu-to-action-sheet-dont-iOS.png 1480w"],
                  "sizes": "(max-width: 740px) 100vw, 740px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
  `}</a>{`
    `}</span></p>
        </Suggestion>
        <h3 {...{
          "id": "when-to-use-1"
        }}>{`When to Use`}</h3>
        <ul>
          <li parentName="ul">{`Use Menus to display a list of options to a user when contextual orientation is valuable in
understanding the potential options.`}</li>
          <li parentName="ul">{`Use Menus to limit the number of screen-related actions required for a user to reach their end
destination.`}</li>
        </ul>
        <h3 {...{
          "id": "when-to-consider-something-else"
        }}>{`When to Consider Something Else`}</h3>
        <ul>
          <li parentName="ul">{`Consider using an Action Sheet if the complexity behind a set of options cannot be simplified into
a menu such as when additional actions like filtering appear immediately after a selection.`}</li>
          <li parentName="ul">{`Consider using an Action Sheet throughout a flow instead if a need arises to switch between a Menu
and Action Sheet. This can prevent a potentially disorienting experience.`}</li>
        </ul>
        <h2 {...{
          "id": "behaviors"
        }}>{`Behaviors`}</h2>
        <h3 {...{
          "id": "pressed-state"
        }}>{`Pressed State`}</h3>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/119c4d0119279b7446b15005b00d337b/536c7/menu-pressed-state.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "25.945945945945947%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAX0lEQVR42q2QywqAIBBF/f/vC6JVUFototFBlBHl9viBWeRdHw6Ha6CslIKcM2qtaK1pOIwGEBFWa8ExfuLfwm0/MIwT5sXivKhDofdwziGEgJQ6FL4SZoaIQJ4/NeENqimLHW73DEcAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "pressed state of list items in a menu",
                "title": "pressed state of list items in a menu",
                "src": "/static/119c4d0119279b7446b15005b00d337b/50383/menu-pressed-state.png",
                "srcSet": ["/static/119c4d0119279b7446b15005b00d337b/1efb2/menu-pressed-state.png 370w", "/static/119c4d0119279b7446b15005b00d337b/50383/menu-pressed-state.png 740w", "/static/119c4d0119279b7446b15005b00d337b/536c7/menu-pressed-state.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "disabled-state"
        }}>{`Disabled State`}</h3>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/367e691ea5473962a17c9c8accd7fd73/536c7/menu-disabled-state.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "25.945945945945947%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAVElEQVR42qWQOQ4AIQwD+f9PoUukADmEEVtsmyKuRyPbDUlUFXNOuDsiIsPRMoCI0XvHWgtqhnNOTWjmMI9P9BqWhcyMMQZEBHtrffL7j4j+yZnwAr5HjHpDTkDgAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "disabled state of list items in a menu",
                "title": "disabled state of list items in a menu",
                "src": "/static/367e691ea5473962a17c9c8accd7fd73/50383/menu-disabled-state.png",
                "srcSet": ["/static/367e691ea5473962a17c9c8accd7fd73/1efb2/menu-disabled-state.png 370w", "/static/367e691ea5473962a17c9c8accd7fd73/50383/menu-disabled-state.png 740w", "/static/367e691ea5473962a17c9c8accd7fd73/536c7/menu-disabled-state.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "selection-state"
        }}>{`Selection State`}</h3>
        <p>{`Selection states delineate a pre-selected item in a menu from its peers, indicating to users the
current selection within the interface. Selection states in the menu are often used in navigation
(e.g., the dropdown navigation paradigm) to indicate current location within a flow.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/b215410e2fcb8940d23ce390b9c11c54/536c7/menu-selection-state.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "25.945945945945947%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAV0lEQVR42qWOrQ6AMBCD9/4vhseDmyEhwNhPWLmVGfRdoKam/VpHs5op5bT6GsFhOjj6wi1BRTttMWRwXjL9DsaC7w9fAWA6A+/uIvIfKNJYO+yq1QR8AIgpjIKyNMiHAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "menu with an item pre-selected, indicated by a blue highlight and checkmark",
                "title": "menu with an item pre-selected, indicated by a blue highlight and checkmark",
                "src": "/static/b215410e2fcb8940d23ce390b9c11c54/50383/menu-selection-state.png",
                "srcSet": ["/static/b215410e2fcb8940d23ce390b9c11c54/1efb2/menu-selection-state.png 370w", "/static/b215410e2fcb8940d23ce390b9c11c54/50383/menu-selection-state.png 740w", "/static/b215410e2fcb8940d23ce390b9c11c54/536c7/menu-selection-state.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "placement"
        }}>{`Placement`}</h3>
        <p>{`Menus should generally be positioned near the edges or center of the screen, as they obscure other
permanent UI elements on the screen when open. Menus should appear below (such as the top of
screen), above (such as the bottom of screen), or on top of (such as the center of screen) the item
that generates them.`}</p>
        <h2 {...{
          "id": "api-guidelines"
        }}>{`API Guidelines`}</h2>
        <h3 {...{
          "id": "methods"
        }}>{`Methods`}</h3>
        <p><strong parentName="p">{`Class name:`}</strong>{` `}<inlineCode parentName="p">{`MenuConfigurable`}</inlineCode>{` `}<strong parentName="p">{`Module name:`}</strong>{` `}<inlineCode parentName="p">{`UIComponentsPlugin`}</inlineCode></p>
        <pre><code parentName="pre" {...{
            "className": "language-swift"
          }}>{`    public init(
        featureData: FeatureMetricsData,
        items: [ContextMenuRowOptionModel],
        localizer: LocalizationAdapting,
        iconAccessibilityLabel: String? = nil,
        buttonVariant: ButtonVariant = .tertiary,
        buttonView: ButtonView,
        showingPopup: Binding<Bool>,
        menuAlignment: PopUpContextMenuAlignment = .standard
    )
`}</code></pre>
        <h3 {...{
          "id": "parameters"
        }}>{`Parameters`}</h3>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Name`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default values`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`featureData`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`the feature name/context and the screen ID in which the component appears.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`items`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`List of ContextMenuRowOptionModel.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`localizer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`localizer to translate default a11y strings.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`iconAccessibilityLabel`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`replaces button accessibility label if passed in; defaults to "related actions".`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`nil`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`buttonVariant`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`styling of trigger button - primary, secondary, tertiary; deafults to tertiary.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`.tertiary`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`buttonView`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`view that trigger button displays.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`showingPopup`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`binding Bool to trigger menu to appear programmatically.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`menuAlignment`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`PopupContextMenuAlignment`}</inlineCode>{` which allows the consumer to set that they want the menu to align. Due to how the position modifier works, this can end up with the menu attempting to render partly outside the screen. If it detects that, it will print an error message and use the standard positioning/alignment. Default is standard.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`.standard`}</td>
            </tr>
          </tbody>
        </table>
        <h2 {...{
          "id": "accessibility-guidelines-1"
        }}>{`Accessibility Guidelines`}</h2>
        <p>{`Coming soon...`}</p>
        <h2 {...{
          "id": "content-guidelines-1"
        }}>{`Content Guidelines`}</h2>
        <ul>
          <li parentName="ul">{`Appropriate content within a Menu should enable users to access actions most relevant to their
task at hand. Using Menus as a way to hide infrequently used information is not recommended.`}</li>
          <li parentName="ul">{`Keep menus as concise as possible and use meaningful supplementary icons.`}</li>
          <li parentName="ul">{`When writing menu list items, refer to the Menus section of the Content Style Guide.`}</li>
        </ul>
      </TabPanel>
    </InternalContent>
    <InternalContent mdxType="InternalContent">
      <TabPanel data-id="Android" mdxType="TabPanel">
        <PackageInfo figma="https://www.figma.com/file/wUewAjqFBnLx2ka2B49FUR/%F0%9F%9A%A7-BETA%3A-Mobile-UI-Components-%F0%9F%9A%A7?type=design&node-id=88%3A13961&mode=design&t=aFvEj4yRb75k5JJT-1" mdxType="PackageInfo" />
        <h2 {...{
          "id": "anatomy-2"
        }}>{`Anatomy`}</h2>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/7fbf5cc820b51cb271e2b625dc163071/536c7/menu-anatomy.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "28.10810810810811%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAr0lEQVR42pWQbQvCMAyE9/9/oKJMkbGxDmGj25q+pT2z4gcFcRoIgVLunrsKr5NzOenUg48dfAiI28aIIJeZ5UvGt6k+vroINg5KKczzDCKC1hrOOXBkMf5V8Omeb3dwrTA0XRFaRHSaRpAlcEr/E2bjwavFsq4IQsScECXuJhYOLdK5fwPYJUz1gHhRUNcGuh1EeME0jjASfTMC+T87DELjPMhQWWut9GhLh2kn8gPrftfZ+IJiRAAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "the basic elements of a menu",
                "title": "the basic elements of a menu",
                "src": "/static/7fbf5cc820b51cb271e2b625dc163071/50383/menu-anatomy.png",
                "srcSet": ["/static/7fbf5cc820b51cb271e2b625dc163071/1efb2/menu-anatomy.png 370w", "/static/7fbf5cc820b51cb271e2b625dc163071/50383/menu-anatomy.png 740w", "/static/7fbf5cc820b51cb271e2b625dc163071/536c7/menu-anatomy.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <ol>
          <li parentName="ol"><strong parentName="li">{`Container:`}</strong>{` A dynamic and lightweight container that houses icons and list items.`}</li>
          <li parentName="ol"><strong parentName="li">{`Icon (Optional):`}</strong>{` Descriptive iconography that precedes and supplements the list item.`}</li>
          <li parentName="ol"><strong parentName="li">{`List Item:`}</strong>{` Text for a menu item.`}</li>
          <li parentName="ol"><strong parentName="li">{`Divider (Optional):`}</strong>{` A break in menu items to group related items or differentiate unrelated
items.`}</li>
        </ol>
        <h2 {...{
          "id": "usage-guidance-2"
        }}>{`Usage Guidance`}</h2>
        <h4 {...{
          "id": "item-prominence-1"
        }}>{`Item Prominence`}</h4>
        <p>{`Within a Menu, the most frequently used items should be in the most prominent location at the top.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/6699d2d7c83ad35087e4e39784f691e7/536c7/menu-item-prominence-android.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "63.51351351351351%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAAB9UlEQVR42oWS3W/SYBTG94+qN+5mGJf4V3jPnWLMlmgieuONsFHcjInKx2RZNNv4KgXaQmlLoe0YrOzn+9a56EKxyZPznvc9eXrOeZ6Nwcjhb5iWzd27VUiq27hb5Ex8Rq4X50PbxXLGMeRZQt7Ld1m3ivSW0BiOcEWRcnBIKvWIra1UHB9ubnLvwf04ylzi8fY2RVG3ivTfDgVe7uySzWZRCgUK+/vs5fPkch/iWFSU+JxOp3mWyWCPJ8mEciytb1A7rgFLrq9J/MIgoFKurB9Z7qdvmBS/ltHdgOhqQRQtWS5/I4oiFotLfN/jIvCoVsqC0FlPaBgDdpQirYFFt9en0VapN9uc1VuoHZVo8Ars1+C+pfYti2FJ8ZJ2KAhNc8jzXJ6TdgdN690StjWdZqtFeP6Upf4Cgs/Uyu8E4TiZ0BSEtujsyZv3fPx5hqZq/Dg9p9FS6Ysf1VWdRqPNLAzwoznZwwLWyF3jw5uRd5UDTrs6PTFyU+3EYjneBMsW3XgzTvoznPmCTPEToxtnrFZZEGq6yVGlijeZxrllO7jCGlM/YDC06Vo+x9o0Vvp79Uh49z8qdwXhl5JQb2ihyh222mJci57l0ugYBMIuF6Efq18qV9fb5k+HJdGhtMl8Lm1zhR/OUa2QSXiJNKf0p3wvJfjwF/y0r/U9I6BeAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "prominence of list items in a menu",
                "title": "prominence of list items in a menu",
                "src": "/static/6699d2d7c83ad35087e4e39784f691e7/50383/menu-item-prominence-android.png",
                "srcSet": ["/static/6699d2d7c83ad35087e4e39784f691e7/1efb2/menu-item-prominence-android.png 370w", "/static/6699d2d7c83ad35087e4e39784f691e7/50383/menu-item-prominence-android.png 740w", "/static/6699d2d7c83ad35087e4e39784f691e7/536c7/menu-item-prominence-android.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h4 {...{
          "id": "iconography-usage-1"
        }}>{`Iconography Usage`}</h4>
        <p>{`Use meaningful supplementary icons to reinforce the Menu item’s title and improve scannability`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/7b50666de39d06b7066bf01c6d23f85e/536c7/menu-iconography-iOS.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "63.51351351351351%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAACBUlEQVR42o2SS2/TQBDH8/HoJVLufA5OnDggg8QBCUIPSVGjlARVBQqJvbYTxJUPkEJVp6BE0Mbx+x0/8md2C1KgD2pptOuZ2d/Of2Zr50sbf+zMtMS6dDyYtouF5Vwy7ufxzfxNq23CeOKPcxNfTwxMv8/wc7EUfm58z437j40p7S/y/4XWNmEn0294sb2N3U4HkiSh0WigXq/jztaWWPm/JD1Cq93Gs+dNAecVb0IFkN8cxCkGMoPKFBRFjvl8jm63i5c7O2i1WmLd23sFwzDgOC76/dfQxh/FOX7+EtCngMxUHDINUZZjva5w1ef7LoqyhKqqUPURAbOrgfym97KCp/sH4KgwCKgSh8yGRbJCb4b07ADZYgDEOrR3j6GOPl1fYUiBw6GCJ/0eVVAgSRJhcRxilQNl8AWYNFCd3gcyhtHbB2D6DcAoyfBmKONusw03jOC5PizLJYk+9bREQuDjyWf4zkxIlzpNkjy+XrKokCQ/7O1jYeaYGiGOJj71ci0AeVEgiDLM7Fy05N5uD9pNQF76UGEY67oAxJTIrSJgxZk0JD8poR95Iq7Ta2Da6P9ARtPjPfS8C7llWYkqq6pEnGYwLVsAZeWWQE3TxIFVTtLoeQTJCqdmijAthL8knwBSe5h+C6BKQK4wjGKSnCAivxskoscxTZ37+fdhMPz9Dv+e8i+eqamNhytZPQAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "using icons in to enhance a menu",
                "title": "using icons in to enhance a menu",
                "src": "/static/7b50666de39d06b7066bf01c6d23f85e/50383/menu-iconography-iOS.png",
                "srcSet": ["/static/7b50666de39d06b7066bf01c6d23f85e/1efb2/menu-iconography-iOS.png 370w", "/static/7b50666de39d06b7066bf01c6d23f85e/50383/menu-iconography-iOS.png 740w", "/static/7b50666de39d06b7066bf01c6d23f85e/536c7/menu-iconography-iOS.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h4 {...{
          "id": "sectioning-1"
        }}>{`Sectioning`}</h4>
        <p>{`Adding titles to Menu sections increases visual noise on a screen.`}</p>
        <SideBySide mdxType="SideBySide">
          <Suggestion status="do" guidance="Use a divider to differentiate sections as necessary." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "696px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/3a12fbf7cae1fcaff2d2384bdaa438f8/82158/menu-do-divider-android.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "91.08108108108108%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Menu with a divider differentiating sections",
                    "title": "Menu with a divider differentiating sections",
                    "src": "/static/3a12fbf7cae1fcaff2d2384bdaa438f8/82158/menu-do-divider-android.png",
                    "srcSet": ["/static/3a12fbf7cae1fcaff2d2384bdaa438f8/1efb2/menu-do-divider-android.png 370w", "/static/3a12fbf7cae1fcaff2d2384bdaa438f8/82158/menu-do-divider-android.png 696w"],
                    "sizes": "(max-width: 696px) 100vw, 696px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
          <Suggestion status="error" guidance="Title Menu sections unless absolutely necessary." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "696px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/62c3c69ad966caf0e2aca61727e448cd/82158/menu-dont-divider-android.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "91.08108108108108%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Menu with subtitles",
                    "title": "Menu with subtitles",
                    "src": "/static/62c3c69ad966caf0e2aca61727e448cd/82158/menu-dont-divider-android.png",
                    "srcSet": ["/static/62c3c69ad966caf0e2aca61727e448cd/1efb2/menu-dont-divider-android.png 370w", "/static/62c3c69ad966caf0e2aca61727e448cd/82158/menu-dont-divider-android.png 696w"],
                    "sizes": "(max-width: 696px) 100vw, 696px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
        </SideBySide>
        <h4 {...{
          "id": "ui-state-changes-1"
        }}>{`UI State Changes`}</h4>
        <p>{`Confusion between when to use a Menu versus an Action Sheet is common. Usage is very context
dependent and should be consistent in a flow.`}</p>
        <Suggestion status="error" guidance="Link between the Menu and Action Sheet, as it may lead to a contextually disorienting experience for the user. Use either the menu or the action sheet in one flow." mdxType="Suggestion">
          <p><span parentName="p" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "740px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/1a304271af6e4e15e9d6a52fece163dc/536c7/menu-menu-to-action-sheet-dont-Android.png",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": "noopener"
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "42.972972972972975%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "Image of a flow that links Menu to Action Sheet after an interaction.",
                  "title": "Image of a flow that links Menu to Action Sheet after an interaction.",
                  "src": "/static/1a304271af6e4e15e9d6a52fece163dc/50383/menu-menu-to-action-sheet-dont-Android.png",
                  "srcSet": ["/static/1a304271af6e4e15e9d6a52fece163dc/1efb2/menu-menu-to-action-sheet-dont-Android.png 370w", "/static/1a304271af6e4e15e9d6a52fece163dc/50383/menu-menu-to-action-sheet-dont-Android.png 740w", "/static/1a304271af6e4e15e9d6a52fece163dc/536c7/menu-menu-to-action-sheet-dont-Android.png 1480w"],
                  "sizes": "(max-width: 740px) 100vw, 740px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
  `}</a>{`
    `}</span></p>
        </Suggestion>
        <h3 {...{
          "id": "when-to-use-2"
        }}>{`When to Use`}</h3>
        <ul>
          <li parentName="ul">{`Use Menus to display a list of options to a user when contextual orientation is valuable in
understanding the potential options.`}</li>
          <li parentName="ul">{`Use Menus to limit the number of screen-related actions required for a user to reach their end
destination.`}</li>
        </ul>
        <h3 {...{
          "id": "when-to-consider-something-else-1"
        }}>{`When to Consider Something Else`}</h3>
        <ul>
          <li parentName="ul">{`Consider using an Action Sheet if the complexity behind a set of options cannot be simplified into
a menu such as when additional actions like filtering appear immediately after a selection.`}</li>
          <li parentName="ul">{`Consider using an Action Sheet throughout a flow instead if a need arises to switch between a Menu
and Action Sheet. This can prevent a potentially disorienting experience.`}</li>
        </ul>
        <h2 {...{
          "id": "behaviors-1"
        }}>{`Behaviors`}</h2>
        <h3 {...{
          "id": "pressed-state-1"
        }}>{`Pressed State`}</h3>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/119c4d0119279b7446b15005b00d337b/536c7/menu-pressed-state.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "25.945945945945947%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAX0lEQVR42q2QywqAIBBF/f/vC6JVUFototFBlBHl9viBWeRdHw6Ha6CslIKcM2qtaK1pOIwGEBFWa8ExfuLfwm0/MIwT5sXivKhDofdwziGEgJQ6FL4SZoaIQJ4/NeENqimLHW73DEcAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "pressed state of list items in a menu",
                "title": "pressed state of list items in a menu",
                "src": "/static/119c4d0119279b7446b15005b00d337b/50383/menu-pressed-state.png",
                "srcSet": ["/static/119c4d0119279b7446b15005b00d337b/1efb2/menu-pressed-state.png 370w", "/static/119c4d0119279b7446b15005b00d337b/50383/menu-pressed-state.png 740w", "/static/119c4d0119279b7446b15005b00d337b/536c7/menu-pressed-state.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "disabled-state-1"
        }}>{`Disabled State`}</h3>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/367e691ea5473962a17c9c8accd7fd73/536c7/menu-disabled-state.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "25.945945945945947%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAVElEQVR42qWQOQ4AIQwD+f9PoUukADmEEVtsmyKuRyPbDUlUFXNOuDsiIsPRMoCI0XvHWgtqhnNOTWjmMI9P9BqWhcyMMQZEBHtrffL7j4j+yZnwAr5HjHpDTkDgAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "disabled state of list items in a menu",
                "title": "disabled state of list items in a menu",
                "src": "/static/367e691ea5473962a17c9c8accd7fd73/50383/menu-disabled-state.png",
                "srcSet": ["/static/367e691ea5473962a17c9c8accd7fd73/1efb2/menu-disabled-state.png 370w", "/static/367e691ea5473962a17c9c8accd7fd73/50383/menu-disabled-state.png 740w", "/static/367e691ea5473962a17c9c8accd7fd73/536c7/menu-disabled-state.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "selection-state-1"
        }}>{`Selection State`}</h3>
        <p>{`Selection states delineate a pre-selected item in a menu from its peers, indicating to users the
current selection within the interface. Selection states in the menu are often used in navigation
(e.g., the dropdown navigation paradigm) to indicate current location within a flow.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/b215410e2fcb8940d23ce390b9c11c54/536c7/menu-selection-state.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "25.945945945945947%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAV0lEQVR42qWOrQ6AMBCD9/4vhseDmyEhwNhPWLmVGfRdoKam/VpHs5op5bT6GsFhOjj6wi1BRTttMWRwXjL9DsaC7w9fAWA6A+/uIvIfKNJYO+yq1QR8AIgpjIKyNMiHAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "menu with an item pre-selected, indicated by a blue highlight and checkmark",
                "title": "menu with an item pre-selected, indicated by a blue highlight and checkmark",
                "src": "/static/b215410e2fcb8940d23ce390b9c11c54/50383/menu-selection-state.png",
                "srcSet": ["/static/b215410e2fcb8940d23ce390b9c11c54/1efb2/menu-selection-state.png 370w", "/static/b215410e2fcb8940d23ce390b9c11c54/50383/menu-selection-state.png 740w", "/static/b215410e2fcb8940d23ce390b9c11c54/536c7/menu-selection-state.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "placement-1"
        }}>{`Placement`}</h3>
        <p>{`Menus should generally be positioned near the edges or center of the screen, as they obscure other
permanent UI elements on the screen when open. Menus should appear below (such as the top of
screen), above (such as the bottom of screen), or on top of (such as the center of screen) the item
that generates them.`}</p>
        <h2 {...{
          "id": "api-guidelines-1"
        }}>{`API Guidelines`}</h2>
        <h3 {...{
          "id": "methods-1"
        }}>{`Methods`}</h3>
        <pre><code parentName="pre" {...{
            "className": "language-kotlin"
          }}>{`  fun MenuUiComponent(
    modifier: Modifier = Modifier,
    menuItems: List<DisplayableMenuItem>,
    alignment: MenuAlignment = MenuAlignment.Default,
    buttonView: @Composable (MenuController) -> Unit
  )
`}</code></pre>
        <h3 {...{
          "id": "parameters-1"
        }}>{`Parameters`}</h3>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Name`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`menuItems`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`List of `}{`[DisplayableMenuItem]`}{` to be displayed in the menu.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`alignment`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Aligns the DropdownMenu. `}{`[MenuAlignment.Default]`}{` uses the android `}{`[androidx.compose.material.DropdownMenu]`}{` alignment which aligns the dropdown to the leading edge, if the dropdown doesn't have enough space it is trailing edge aligned. `}{`[MenuAlignment.Center]`}{` aligns the DropdownMenu to the center of the `}{`[buttonView]`}{`. `}{`[MenuAlignment.CenterScreen]`}{` aligns the DropdownMenu to center of the screen.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`buttonView`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Composable to be used as the trigger to open the menu.`}</td>
            </tr>
          </tbody>
        </table>
        <h2 {...{
          "id": "accessibility-guidelines-2"
        }}>{`Accessibility Guidelines`}</h2>
        <p>{`Coming soon...`}</p>
        <h2 {...{
          "id": "content-guidelines-2"
        }}>{`Content Guidelines`}</h2>
        <ul>
          <li parentName="ul">{`Appropriate content within a Menu should enable users to access actions most relevant to their
task at hand. Using Menus as a way to hide infrequently used information is not recommended.`}</li>
          <li parentName="ul">{`Keep menus as concise as possible and use meaningful supplementary icons.`}</li>
          <li parentName="ul">{`When writing menu list items, refer to the Menus section of the Content Style Guide.`}</li>
        </ul>
      </TabPanel>
    </InternalContent>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      